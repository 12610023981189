
export const S3_BASE_URL = 'https://amplify-amplify26db67160e5b4-staging-151303-deployment.s3.ap-south-1.amazonaws.com';
export const Academics_Brochure_Url = `${S3_BASE_URL}/academics_brochure/AY2223_Sales_Brochure.pdf`;
  
export const about_us_strings_url = `https://ix8zbftryg.execute-api.ap-south-1.amazonaws.com/default/myTest`;

export const home_page_strings_url = `https://xfmjs8g6uf.execute-api.ap-south-1.amazonaws.com/default/HomePageStrings`;

export const academics_page_strings_url = `https://auee1vhx87.execute-api.ap-south-1.amazonaws.com/default/AcademicsPageStrings`;

export const coactivities_page_strings_url = `https://0huhxi54f2.execute-api.ap-south-1.amazonaws.com/default/CoActivitiesPage`;


// Mandatory Public disclosure


export const public_disclosure = `${S3_BASE_URL}/Public+Disclosure`;

export const recognition_certificate = `${public_disclosure}/Certificate_of_recognition.pdf`;

export const deo_certificate = `${public_disclosure}/Deo_certificate_submitted_by_school+.pdf`;

export const fire_certificate = `${public_disclosure}/Fire_certificate.pdf`;

export const lease_deed = `${public_disclosure}/Lease_deed.pdf`;

export const no_objection_certificate = `${public_disclosure}/No_objection_certificate.pdf`;

export const sanitary_certificate =  `${public_disclosure}/Sanitary_certificate.pdf`;

export const self_declaration = `${public_disclosure}/Self_declaration.pdf`;

export const stability_certificate =  `${public_disclosure}/Stability_certificate.pdf`;

export const trust_deed = `${public_disclosure}/Trust_deed.pdf`;

export const building_safety = `${public_disclosure}/Building_safety.pdf`;

export const revised_certificate_of_land = `${public_disclosure}/revised_certificate_of_land.pdf`

export const fees_structure = `${public_disclosure}/Fees_structure.pdf`;

export const annual_academic_planner = `${public_disclosure}/Annual_academic_planner.pdf`;

export const parents_teachers_association = `${public_disclosure}/Parents_teachers_association.pdf`;

export const school_management_committe =`${public_disclosure}/School_management_committe.pdf`;

export const affiliation_letter = `${public_disclosure}/AFFILIATION_LETTER.pdf`;
