import "./schoolDetails.scss";
import PictureHolder from "../../../common/pictureHolder";
import { S3_BASE_URL } from "../../../constants/aws_s3_constants";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope, faPhone, faLocationDot } from '@fortawesome/free-solid-svg-icons'



export default function SchoolDetails() {
  const schoolLogo = `${S3_BASE_URL}/images/schoolLogo.jpeg`;
  const Unavailable = `${S3_BASE_URL}/images/unavailable-image.jpg`;
  const schoolLogoSmall = `${S3_BASE_URL}/images/schoolLogoSmall.jpeg`;

  return (
<div className="app-school-details-container">    
    <div className="container">
      <div className="row">
        <div className="col-md-5 col-sm-4 col-xs-12 inner">
          <a href="/">
            <div className="app-school-details-logo-container col-xs-4">
              <PictureHolder
              imageLarge={schoolLogo}
              ImageSmall={schoolLogoSmall}
              ImageUnavailable={Unavailable}
              altText="School logo"
              />
            </div>
            <div className="app-school-details-name-container col-xs-8">
              <h1 className="fs-2"> ELF'S PUBLIC SCHOOL </h1>
            </div>
          </a>
        </div>

      <div className="top-bar-level-1 col-md-7 col-sm-4 col-xs-4 d-none d-sm-block">
        <div className="top-bar-level-2">
          <div className="top-bar-level-3">
            <li className="phone"><span><FontAwesomeIcon icon={faPhone} /></span> <div className="rtc"><h4>Call</h4><p>9789296860</p></div></li>
            <li className="email"><span><FontAwesomeIcon icon={faEnvelope} /></span><div className="rtc"><h4>Email us</h4><p>elfscbseschool@gmail.com</p></div></li>
            <li className="map"><span><FontAwesomeIcon icon={faLocationDot} /></span><div className="rtc"><h4>School Located</h4><p>Mallur,Salem</p></div></li>
          </div>
        </div>
      </div>
    
    <div className="col-md-2"></div>
    </div>
</div>
<div className='app-school-details-loader-line' />
</div>

  );
}
