import "./header.scss";
import { useLocation, Link } from "react-router-dom";
import { RouteConstants } from "../../constants/routeConstants";
import SchoolDetails from "./schoolDetails/schoolDetails";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleRight, faHouse } from '@fortawesome/free-solid-svg-icons'

const menu_links = {
    HOME:"Home",
    ABOUT_US:"About us",
    ACADEMICS:"Academics",
    CO_CURRICULUM_ACTIVITIES:"Co-Curriculum",
    CELEBRATIONS:"Celebrations",
    MANDATORY_PUBLIC_DISCLOSURE:"Mandatory Public Disclosure",
    CONTACT_US:"Contact us"
};


function Header() {
  const location = useLocation();
  var pageName = (location.pathname).slice(1);
  switch(pageName) {
      case "publicdisclosure" : 
        pageName = "Mandatory Public Disclosure";
        break;
      case "activities" : 
        pageName = "Co-Curriculum";
        break;
      case "contactus" : 
        pageName = "Contact us";
        break;
      case "aboutus" : 
        pageName = "About us";
        break;
      case "" : 
        pageName = "none";
        break;
  }
  let getClassName='';
  const menu_links_items = Object.keys(RouteConstants).map((items, index) => {
    getClassName = location.pathname === RouteConstants[items] ? 'active app-header-link-inactive nav-link' : 'app-header-link-inactive nav-link';
    return (
      <li key={index} className="app-header-menu-items nav-item">
        <Link to={RouteConstants[items]} className={getClassName}>
          {menu_links[items]}
        </Link>
      </li>
    );
  });
  return (
    <header className="App-header">
      <SchoolDetails />
      <nav id="navbar" className="app-header-menu-container navbar navbar-expand-lg bg-body-tertiary">
        <div className="container-fluid">
          <button className="navbar-toggler me-2" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
                <ul className="navbar-nav me-auto mb-2 mb-lg-0">{menu_links_items}</ul>
          </div>
        </div>
      </nav>
      <div className="pageName" id={pageName}>
        <div className="container">
          <div className="row">
          <div className="d-flex col-xl-12 col-md-12 inner1">
            <div className="pageNameInner">
              <h1 className={pageName}>{pageName}</h1>
            </div>
          </div>
          <div className="d-flex col-xl-12 col-md-12">
            <div className="breadcrumb">
              <ul>
                <li className="home"><a href="/"><FontAwesomeIcon icon={faHouse} />{"Home"}</a></li>
                <li><FontAwesomeIcon icon={faAngleRight} /></li>
                <li><a href={location.pathname} className={getClassName}>{pageName}</a></li>
              </ul>
            </div>
          </div>
          
          </div>          
        </div>
      </div>
    </header>
  );
}

export default Header;
