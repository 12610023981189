import React from "react";
import './loader.scss';

export default function Loader() {
  return (
    <main className='app-loader'>
      <section className="app-loading-container">
          <div className="app-loading-content"> 
            <div> Still Loading ... </div>
          </div>
      </section>
    </main>
  );
}
